


















































































import Vue from "vue";
import { Steps, Step, Button, Cell, CellGroup, Checkbox, CheckboxGroup, Swipe, SwipeItem, Icon, RadioGroup, Radio, Row, Col, Toast, Dialog, Field, Picker, Popup } from "vant";
export default Vue.extend({
  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  data() {
    return {
      step: 0,
      maxStep: 3,
      agencyInfo: {
        agencyId: 0,
        name: "-",
        contactName: "-",
        phone: "-",
        profitList: [] as Profit[],
        siteList: [] as Site[]
      },
      siteList: [] as Site[]
    };
  },
  watch: {
    step: "onStepChange",
    "pickDevice.on": "onPickDeviceStatusChange"
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      return this.$axios.post("/api/manage/business/getDianbaoAgencyInfo", { agencyId: 76 }).then(e => {
        const data = e.data.data;
        this.agencyInfo = data;
        this.siteList = data.siteList;
      });
    },
    save() {
      Toast.fail("not yet");
    },
    onStepChange(step: number) {
      const swiper = this.$refs.swiper as Swipe;
      swiper.swipeTo(step);
    },
    toggle(index: number) {
      const checkboxes = this.$refs.agencys as Checkbox[];
      checkboxes[index].toggle();
    }
  }
});

interface Profit {
  agencyId: number;
  contactName: string;
  name: string;
  phone: string;
  profit: number;
}

interface Site {
  agencyId: number;
  contactName: string;
  name: string;
  phone: string;
  profit: number;
  profitList: Profit[];
}
